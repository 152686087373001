@import "./_variables";
@import "./animations";

html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  scroll-padding: 500px; /* Increase this value to make scrolling slower */
}

.desktop-container {
  background-color: $darkest_grey;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow: auto;
  height: 700vh;

  &.header {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
  &.parallax-container {
    width: 100%;
    height: 100%;
  }
}
