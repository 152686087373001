@import "../../_variables";

@media (min-width: 1400px) {
  .founder-info {
    width: 20rem;
    height: 25rem;
    border-radius: 1.5rem;
    background-color: $darkest_grey;
    border-color: $grey_50;
    border-width: 0.2rem;
    border-style: solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .founder-image {
      width: 14rem;
      height: 14rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .founder-text {
      h2 {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 1.4rem;
        color: $grey_50;
        text-align: center;
      }

      .link_image_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-top: 1rem;
        border-radius: 0%;
        img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          border-radius: 0%;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (min-width: 450px) and (max-width: 1400px) {
  .founder-info {
    width: 60%;
    height: 60%;
    border-radius: 1.5rem;
    background-color: $darkest_grey;
    border-color: $grey_50;
    border-width: 0.2rem;
    border-style: solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .founder-image {
      width: 7rem;
      height: 7rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .founder-text {
      h2 {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 1.4rem;
        color: $grey_50;
        text-align: center;
      }

      .link_image_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-top: 1rem;
        border-radius: 0%;
        img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          border-radius: 0%;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .founder-info {
    width: 20rem;
    height: 25rem;
    border-radius: 1.5rem;
    background-color: $darkest_grey;
    border-color: $grey_50;
    border-width: 0.2rem;
    border-style: solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .founder-image {
      width: 14rem;
      height: 14rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .founder-text {
      h2 {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 1.4rem;
        color: $grey_50;
        text-align: center;
      }

      .link_image_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-top: 1rem;
        border-radius: 0%;
        img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          border-radius: 0%;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
