@import "../../_variables";

.logo-container {
  display: flex;
  justify-content: left;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  margin-left: 3rem;
  align-items: center;
}

.logo-container img {
  width: 5rem;
  object-fit: contain;
}

.logo-text {
  display: flex;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 4rem;
  color: $primary;
  text-align: center;
  margin-bottom: 1rem;
}

.logo-text p {
  margin: 0;
  padding: 0;
}

.logo-text__guava {
  color: $primary;
  align-self: flex-start;
}
