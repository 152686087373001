@import "../../_variables";

@media (max-width: 1400px) {
  .parallax-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 705vh;
    overflow: hidden;

    .contact-button-container {
      position: fixed;
      bottom: 3%;
      right: 2%;
      z-index: 999;
    }

    .header {
      position: fixed;
      top: 0vh;
      width: 100%;
      height: 15%;
      z-index: 1;
      display: flex;
      justify-content: left;
    }

    .text-container {
      position: fixed;
      top: 60%;
      left: 10%;
      h1 {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 2rem;
        color: $background;
        text-align: left;
      }
    }

    .image-container {
      position: fixed;
      top: 18%;
      width: 26%;
      left: 10%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .form-container {
      position: fixed;
      top: 15%;
      right: 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .welcome-text {
        position: absolute;
        width: 40vw;
        font-family: "Poppins";
        font-weight: 400;
        font-size: 1.5rem;
        color: $background;
        text-align: center;
        top: 25vh;
        right: 50%;
        z-index: 5;
      }
    }

    .save-card-container {
      position: absolute;
      top: 150vh;
      left: 5%;
      height: 80%;
      width: 90%;
      transform: translateY(-50%);
      overflow: hidden;
    }

    .save-card-container-fixed {
      position: fixed;
      top: 10%;
      left: 5%;
      height: 80%;
      width: 90%;
      z-index: 2;
      overflow: hidden;
    }

    .contribute-card-container {
      position: absolute;
      top: 300vh;
      left: 5%;
      height: 80%;
      width: 90%;
      transform: translateY(-50%);
      z-index: 3;
      overflow: hidden;
    }

    .contribute-card-container-fixed {
      position: fixed;
      top: 10%;
      left: 5%;
      height: 80%;
      width: 90%;
      transform: translateY(-50%);
      z-index: 3;
      overflow: hidden;
    }

    .rewards-card-container {
      position: absolute;
      top: 450vh;
      left: 5%;
      height: 80%;
      width: 90%;
      transform: translateY(-50%);
      z-index: 4;
      overflow: hidden;
    }

    .rewards-card-container-fixed {
      position: fixed;
      top: 10%;
      left: 5%;
      height: 80%;
      width: 90%;
      z-index: 3;
    }

    .last-page {
      position: relative;
      background-color: $darkest_grey;
      top: 510vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 220vh;
      width: 100%;
      z-index: 4;
    }
    .last-page::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0.3vh;
      background-color: $background;
      z-index: 4;
    }

    .p2 {
      position: absolute;
      top: 3%;
      width: 100%;
      font-family: "Poppins";
      font-weight: 400;
      font-size: 3rem;
      color: $background;
      text-align: center;
      margin-bottom: 55%;
      z-index: 999;
    }

    .founder-info-container {
      position: absolute;
      width: 100%;
      height: 50%;
      top: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      z-index: 5;

      .founder {
        width: 50%;
        height: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 100%;
        border-radius: 1rem;
        object-fit: contain;
        z-index: 5;
      }
    }
    .pricing-text {
      position: absolute;
      top: 110vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      z-index: 5;

      h1 {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 3rem;
        color: $background;
        text-align: center;
        margin-bottom: 1rem;
        z-index: 5;
      }
    }

    .download-container {
      position: absolute;
      top: 125vh;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      z-index: 5;
    }

    .p3 {
      position: absolute;
      top: 145vh;
      width: 100%;
      font-family: "Poppins";
      font-weight: 400;
      font-size: 3rem;
      color: $background;
      text-align: center;
      z-index: 999;
    }

    @media (max-width: 1400px) and (min-width: 949px) {
      .footer-text {
        position: absolute;
        top: 190vh;
        left: 3vw;
        width: 100%;
        font-family: "Poppins";
        font-weight: 400;
        font-size: 0.7rem;
        color: $background;
        text-align: left;
        z-index: 999;
      }
      .footer-text-right {
        position: absolute;
        top: 190vh;
        right: 10vw;
        width: 100%;
        font-family: "Poppins";
        font-weight: 400;
        font-size: 0.7rem;
        color: $background;
        text-align: right;
        z-index: 999;

        &:hover {
          cursor: pointer;
        }
      }
    }
    @media (max-width: 949px) {
      .footer-text {
        position: absolute;
        top: 175vh;
        width: 100%;
        font-family: "Poppins";
        font-weight: 400;
        font-size: 0.7rem;
        color: $background;
        text-align: center;
        z-index: 999;
      }
      .footer-text-right {
        position: absolute;
        top: 165vh;
        width: 100%;
        font-family: "Poppins";
        font-weight: 400;
        font-size: 0.7rem;
        color: $background;
        text-align: center;
        z-index: 999;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .social-media-container {
      position: absolute;
      top: 172vh;
      margin-top: 5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      z-index: 5;

      img {
        width: 5rem;
        height: 5rem;
        object-fit: contain;
        z-index: 5;
      }
      .tiktok {
        rotate: 20deg;
        margin-right: 1.5rem;
      }
      .instagram {
        rotate: -12deg;
      }
      .facebook {
        rotate: -63deg;
        margin-left: 2rem;
      }
      .linkedin {
        rotate: 47deg;
        margin-right: 1.5rem;
      }
      .x {
        rotate: 21deg;
        margin-left: 0.75rem;
      }
    }
  }
}

@media (min-width: 1400px) {
  .parallax-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 705vh;
    overflow: hidden;

    .contact-button-container {
      position: fixed;
      bottom: 3%;
      right: 2%;
      z-index: 999;
    }

    .header {
      position: fixed;
      top: 0vh;
      width: 100%;
      height: 15%;
      z-index: 1;
      display: flex;
      justify-content: left;
    }

    .text-container {
      position: fixed;
      top: 40%;
      left: 10%;
      h1 {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 2.5rem;
        color: $background;
        text-align: left;
      }
    }

    .image-container {
      position: fixed;
      top: 18%;
      width: 26%;
      left: 35%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .form-container {
      position: fixed;
      top: 20%;
      right: 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .welcome-text {
        position: absolute;
        width: 20vw;
        font-family: "Poppins";
        font-weight: 400;
        font-size: 1.5rem;
        color: $background;
        text-align: center;
        top: 25vh;
        right: 50%;
        z-index: 5;
      }
    }

    .save-card-container {
      position: absolute;
      top: 150vh;
      left: 5%;
      height: 80%;
      width: 90%;
      transform: translateY(-50%);
    }

    .save-card-container-fixed {
      position: fixed;
      top: 10%;
      left: 5%;
      height: 80%;
      width: 90%;
      z-index: 2;
    }

    .contribute-card-container {
      position: absolute;
      top: 300vh;
      left: 5%;
      height: 80%;
      width: 90%;
      transform: translateY(-50%);
      z-index: 3;
    }

    .contribute-card-container-fixed {
      position: fixed;
      top: 10%;
      left: 5%;
      height: 80%;
      width: 90%;
      transform: translateY(-50%);
      z-index: 3;
    }

    .rewards-card-container {
      position: absolute;
      top: 450vh;
      left: 5%;
      height: 80%;
      width: 90%;
      transform: translateY(-50%);
      z-index: 4;
    }

    .rewards-card-container-fixed {
      position: fixed;
      top: 10%;
      left: 5%;
      height: 80%;
      width: 90%;
      z-index: 3;
    }

    .last-page {
      position: relative;
      background-color: $darkest_grey;
      top: 510vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 220vh;
      width: 100%;
      z-index: 4;
    }
    .last-page::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0.3vh;
      background-color: $background;
      z-index: 4;
    }

    .p2 {
      position: absolute;
      top: 3%;
      font-family: "Poppins";
      font-weight: 400;
      font-size: 3.5rem;
      color: $background;
      text-align: center;
      margin-bottom: 55%;
      z-index: 999;
    }

    .founder-info-container {
      position: absolute;
      top: 10%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10rem;
      z-index: 5;

      img {
        width: 100%;
        border-radius: 1rem;
        object-fit: contain;
        z-index: 5;
      }
    }

    .pricing-text {
      position: absolute;
      top: 110vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      z-index: 5;

      h1 {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 3rem;
        color: $background;
        text-align: center;
        margin-bottom: 1rem;
        z-index: 5;
      }
    }

    .download-container {
      position: absolute;
      top: 125vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      z-index: 5;
    }

    .p3 {
      position: absolute;
      top: 145vh;
      width: 100%;
      font-family: "Poppins";
      font-weight: 400;
      font-size: 3rem;
      color: $background;
      text-align: center;
      z-index: 999;
    }

    .footer-text {
      position: absolute;
      top: 190vh;
      left: 3vw;
      width: 100%;
      font-family: "Poppins";
      font-weight: 400;
      font-size: 0.8rem;
      color: $background;
      text-align: left;
      z-index: 999;
    }
    .footer-text-right {
      position: absolute;
      top: 190vh;
      right: 10vw;
      width: 100%;
      font-family: "Poppins";
      font-weight: 400;
      font-size: 0.8rem;
      color: $background;
      text-align: right;
      z-index: 999;

      &:hover {
        cursor: pointer;
      }
    }

    .social-media-container {
      position: absolute;
      top: 174.5vh;
      margin-top: 5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      z-index: 5;

      img {
        width: 5rem;
        height: 5rem;
        object-fit: contain;
        z-index: 5;
      }
      .tiktok {
        rotate: 20deg;
        margin-right: 1.5rem;
      }
      .instagram {
        rotate: -12deg;
      }
      .facebook {
        rotate: -63deg;
        margin-left: 2rem;
      }
      .linkedin {
        rotate: 47deg;
        margin-right: 1.5rem;
      }
      .x {
        rotate: 21deg;
        margin-left: 0.75rem;
      }
    }
  }
}
