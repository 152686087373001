@import "../../_variables";

@media (min-width: 1400px) {
  .info-card {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 3rem;
    justify-content: center;
    align-items: center;
    text-align: left;
    overflow: hidden;

    .info-card-title {
      position: absolute;
      top: 5%;
      left: 5rem;
      width: 18%;
      height: 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 2rem;

      h2 {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 1.5rem;
        margin: 0;
        padding: 0;
      }
    }

    p {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 3rem;
      margin: 5rem;
      padding: 0;
      margin-right: 35.2%;
      margin-top: 5rem;
    }

    .info-card-img {
      .image-class {
        position: absolute;
        bottom: 0;
        left: 47%;
        width: 70%;
        height: 100%;
        object-fit: contain;
        overflow: hidden !important;
        overflow-x: hidden; /* Hide horizontal overflow */
        overflow-y: visible; /* Allow vertical overflow */
      }

      .video-class {
        position: absolute;
        bottom: 10%;
        left: 42.5%;
        width: 80%;
        height: 80%;
        object-fit: contain;
        overflow: hidden !important;
        overflow-x: hidden; /* Hide horizontal overflow */
        overflow-y: visible; /* Allow vertical overflow */
      }
    }

    .replay-container {
      position: absolute;
      bottom: 2%;
      right: 11.5%;
      width: 12%;
      height: 5%;
      border-radius: 9999rem;
      border-style: none;
      padding: 1rem;
      z-index: 5;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      p {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 0.75rem;
        color: $background;
        text-align: center;
        margin: 0;
      }

      img {
        width: 1rem;
        height: 1rem;
        object-fit: contain;
        margin-left: 0.5rem;

        &:hover {
          filter: saturate(0.1);
          cursor: pointer;
        }
      }
    }
  }
}

@media (min-width: 450px) and (max-width: 1400px) {
  .info-card {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 3rem;
    justify-content: center;
    align-items: center;
    text-align: left;
    overflow: hidden;

    .info-card-title {
      position: absolute;
      top: 5%;
      left: 5rem;
      width: 30%;
      min-width: 10rem;
      height: 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 2rem;
      font-size: 1.5rem;

      h2 {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 1.5rem;
        margin: 0;
        padding: 0;
      }
    }

    .text-class {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 1.5rem;
      margin: 5rem;
      padding: 0;
      margin-right: 35.2%;
      margin-top: 5rem;
    }

    .info-card-img {
      .image-class {
        position: absolute;
        bottom: 0;
        left: 47%;
        width: 70%;
        height: 100%;
        scale: 1.2;
        object-fit: contain;
        overflow: hidden;
      }

      .video-class {
        position: absolute;
        bottom: 3%;
        right: 7%;
        width: 20%;
        height: 100%;
        object-fit: contain;
        overflow: hidden;
      }
    }

    .replay-container {
      position: absolute;
      bottom: 2%;
      right: 7%;
      width: 20%;
      min-width: 7rem;
      height: 5%;
      border-radius: 9999rem;
      border-style: none;
      padding: 1rem;
      z-index: 5;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      p {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 0.75rem;
        color: $background;
        text-align: center;
        margin: 0;
      }

      img {
        width: 1rem;
        height: 1rem;
        object-fit: contain;
        margin-left: 0.5rem;

        &:hover {
          filter: saturate(0.1);
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .info-card {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 3rem;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    overflow: hidden;

    .info-card-title {
      position: absolute;
      top: 5%;
      width: 50%;
      height: 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 2rem;

      h2 {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 1.5rem;
        margin: 0;
        padding: 0;
      }
    }

    .text-class {
      position: inherit;
      font-family: "Poppins";
      font-weight: 600;
      font-size: 3rem;
      padding: 0;
      margin: 6%;
      margin-top: 20%;
    }

    .replay-container {
      position: absolute;
      bottom: 14%;
      width: 25%;
      height: 5%;
      left: 33.5%;
      border-radius: 9999rem;
      border-style: none;
      padding: 1rem;
      z-index: 5;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      p {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 0.75rem;
        color: $background;
        text-align: center;
        margin: 0;
      }

      img {
        width: 1rem;
        height: 1rem;
        object-fit: contain;
        margin-left: 0.5rem;

        &:hover {
          filter: saturate(0.1);
          cursor: pointer;
        }
      }
    }
  }
}
