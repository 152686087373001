@import "../../_variables";
@media (max-width: 450px) {
  .verification-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-left: 10%;

    .header-text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 1rem;
        color: $background;
        text-align: center;
      }
    }

    .verification-input-container {
      background-color: $darkest_grey;
      border-color: $grey_50;
      border-width: 0.2rem;
      border-style: solid;
      padding: 0.5rem;
      border-radius: 0.5rem;
    }
    .verification-input-character {
      background-color: $darkest_grey;
      border-color: $grey_50;
      border-width: 0.2rem;
      border-style: solid;
      padding: 0.5rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      font-family: "Poppins";
      font-weight: 600;
      font-size: 1.5rem;
      color: $background;
    }
    .verification-input-character-inactive {
      background-color: $darkest_grey;
      padding: 0.5rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      font-family: "Poppins";
      font-weight: 600;
      font-size: 1.5rem;
      color: $background;
    }
    .verification-input-character-selected {
      background-color: $grey_50;
    }

    .resend-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      width: 100%;

      p {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 0.9rem;
        color: $background;
        text-align: center;
      }
      button {
        background-color: transparent;
        border-color: transparent;
        border-width: 0.2rem;
        border-style: solid;
        border-radius: 0.5rem;
        padding: 0.5rem;
        font-family: "Poppins";
        font-weight: 400;
        font-size: 0.9rem;
        color: $primary;
        text-align: center;

        &:hover {
          cursor: pointer;
          color: $primary_variant;
        }
      }
    }
  }
}

@media (min-width: 450px) {
  .verification-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 25rem;
    margin-top: 9rem;

    .header-text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 1rem;
        color: $background;
        text-align: center;
      }
    }

    .verification-input-container {
      background-color: $darkest_grey;
      border-color: $grey_50;
      border-width: 0.2rem;
      border-style: solid;
      padding: 0.5rem;
      border-radius: 0.5rem;
    }
    .verification-input-character {
      background-color: $darkest_grey;
      border-color: $grey_50;
      border-width: 0.2rem;
      border-style: solid;
      padding: 0.5rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      font-family: "Poppins";
      font-weight: 600;
      font-size: 1.5rem;
      color: $background;
    }
    .verification-input-character-inactive {
      background-color: $darkest_grey;
      padding: 0.5rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      font-family: "Poppins";
      font-weight: 600;
      font-size: 1.5rem;
      color: $background;
    }
    .verification-input-character-selected {
      background-color: $grey_50;
    }

    .resend-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      width: 100%;

      p {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 0.9rem;
        color: $background;
        text-align: center;
      }
      button {
        background-color: transparent;
        border-color: transparent;
        border-width: 0.2rem;
        border-style: solid;
        border-radius: 0.5rem;
        padding: 0.5rem;
        font-family: "Poppins";
        font-weight: 400;
        font-size: 0.9rem;
        color: $primary;
        text-align: center;

        &:hover {
          cursor: pointer;
          color: $primary_variant;
        }
      }
    }
  }
}
