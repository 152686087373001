@import "../../_variables";

@media (max-width: 450px) {
  .form-container-class {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    height: 30rem;
    margin-left: 20%;

    .secure-button {
      margin-bottom: 10rem;
      width: 100%;
    }

    h1 {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 2rem;
      color: $background;
      text-align: center;
      margin-bottom: 1rem;
    }

    .form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
      width: 80%;
    }

    .input-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 0.5rem;
      width: 100%;

      .check {
        position: absolute;
        right: 0.5rem;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 1rem;
        height: 1rem;
        background-color: transparent;
        border-style: none;
      }
    }

    input {
      height: 2rem;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      width: 100%;
      font-family: "Poppins";
      font-weight: 400;
      font-size: 1rem;
      color: $background;
      text-align: left;
      background-color: $darkest_grey;
      border-width: 0.1rem;
      border-color: $grey_50;
      border-style: solid;
    }

    input:focus {
      outline: none;
      border-color: $primary;
    }

    input::placeholder {
      color: $grey_50;
    }

    button {
      height: 3rem;
      width: 100%;
      align-items: center;
      justify-content: center;
      border-radius: 9999rem;
      font-family: "Poppins";
      font-weight: 400;
      font-size: 1rem;
      color: $darkest_grey;
      text-align: center;
      background-color: $primary;
      border: none;
      margin-bottom: 5rem;
      box-sizing: border-box;

      transition: all 0.25s ease;

      &:hover {
        background: $grey_50;
        color: $primary;
        cursor: crosshair;
      }

      &.onclick {
        width: 3rem;
        height: 3rem;
        border: 0.2rem solid $darkest_grey;
        border-left-color: $secondary;
        font-size: 0;
        animation: rotating 1.5s 0.25s linear infinite;

        &:after {
          content: "";
        }

        &:hover {
          color: $darkest_grey;
          background: $primary_variant;
          cursor: pointer;
        }
      }

      &.validate {
        font-size: 1rem;
        color: $darkest_grey;
        background: $primary;
      }
    }

    @keyframes rotating {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}

@media (min-width: 450px) {
  .form-container-class {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 23rem;
    height: 30rem;

    h1 {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 2rem;
      color: $background;
      text-align: center;
      margin-bottom: 1rem;
    }

    .form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
    }

    .input-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 0.5rem;
      width: 100%;

      .check {
        position: absolute;
        right: 0.5rem;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 1rem;
        height: 1rem;
        background-color: transparent;
        border-style: none;
      }
    }

    input {
      height: 2rem;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      width: 100%;
      font-family: "Poppins";
      font-weight: 400;
      font-size: 1rem;
      color: $background;
      text-align: left;
      background-color: $darkest_grey;
      border-width: 0.1rem;
      border-color: $grey_50;
      border-style: solid;
    }

    input:focus {
      outline: none;
      border-color: $primary;
    }

    input::placeholder {
      color: $grey_50;
    }

    button {
      /* Your existing button styles */
      height: 3rem;
      width: 100%;
      align-items: center;
      justify-content: center;
      border-radius: 9999rem;
      font-family: "Poppins";
      font-weight: 400;
      font-size: 1rem;
      color: $darkest_grey;
      text-align: center;
      background-color: $primary;
      border: none;
      margin-bottom: 5rem;
      box-sizing: border-box;

      transition: all 0.25s ease;

      &:hover {
        background: $grey_50;
        color: $primary;
        cursor: pointer;
      }

      &.onclick {
        width: 3rem;
        height: 3rem;
        border: 0.2rem solid $darkest_grey;
        border-left-color: $secondary;
        font-size: 0;
        animation: rotating 1.5s 0.25s linear infinite;

        &:after {
          content: "";
        }

        &:hover {
          color: $darkest_grey;
          background: $primary_variant;
          cursor: pointer;
        }
      }

      &.validate {
        font-size: 1rem;
        color: $darkest_grey;
        background: $primary;
      }
    }

    @keyframes rotating {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}
