@import "../../_variables";

.contact-popup {
  width: 16rem;
  height: 8rem;
  background-color: $background;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 0.8rem;
    color: $primary_variant;
    text-align: center;
  }
  .email-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: $primary;
    border-radius: 9999rem;
    padding: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    .email {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 0.8rem;
      color: $primary_variant;
      text-align: center;
      text-decoration: none;
    }
  }
}
