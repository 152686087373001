@import "../../_variables";

.contact-button {
  width: 3rem;
  height: 3rem;
  background-color: $primary_variant;
  border-radius: 9999rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  z-index: 999;

  .chat-icon {
    width: 50%;
    height: 50%;
    object-fit: contain;
  }

  .chat-variant-icon {
    display: none;
  }

  &:hover {
    animation: rotating 0.4s linear;
    cursor: pointer;
    width: 3.2rem;
    height: 3.2rem;
    background-color: $primary;

    .chat-icon {
      display: none;
    }

    .chat-variant-icon {
      display: inline-block;
      width: 50%;
      height: 50%;
      object-fit: contain;
    }
  }
}

.popup {
  position: fixed;
  bottom: 12%;
  right: 2%;
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  transform-origin: bottom right;
  transform: scale(0);
  transition: transform 0.3s ease;
}

.popup.open {
  transform: scale(1);
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
