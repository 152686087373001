$primary: #58e07c;
$primary_variant: #105623;
$secondary: #e37073;
$secondary_variant: #a02b3c;
$background: #fcf9f1;
$background_subtext: rgba(252, 249, 241, 0.5);
$grey: #505050;
$grey_50: rgba(80, 80, 80, 0.5);
$grey_20: rgba(80, 80, 80, 0.2);
$dark_grey: #292929;
$darkest_grey: #212121;
$mid_grey: #333333;
$tile_grey: rgba(80, 80, 80, 0.1);
$tile_green: rgba(88, 224, 124, 0.3);
$yellow: #ffc947;
$diamond: #7bdadd;
