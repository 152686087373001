@import "../../_variables";

@media (max-width: 450px) {
  .parallax-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    height: auto;

    .header {
      position: relative;
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        width: 14%;
        height: auto;
        object-fit: contain;
      }

      h1 {
        font-family: "Poppins";
        font-size: 1.5rem;
        margin-left: 1rem;
        text-align: center;
      }
    }

    .text-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-self: center;
      height: 5rem;
      width: auto;
    }
    .text-container h1 {
      width: 80%;
      font-size: 1.5rem;
    }

    .welcome-text {
      font-family: "Poppins";
      font-weight: 400;
      font-size: 1.5rem;
      color: $background;
      text-align: center;
      padding-top: 10vh;
      padding-bottom: 10vh;

      z-index: 5;
    }

    .save-card-container,
    .contribute-card-container,
    .rewards-card-container {
      position: static;
      transform: none;
      margin-bottom: 5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      overflow: hidden;

      p {
        font-size: 1rem;
      }

      .info-card {
        height: 35rem;
        width: 90%;
        margin-bottom: 5rem;
        border-radius: 2rem;
        display: flex;
        justify-content: center;

        .info-card-img {
          .image-class {
            position: absolute;
            width: 100%;
            height: 100%;
            scale: 1.3;
            object-fit: contain;
            top: 8%;
            right: 5%;
            overflow: hidden;
          }
          .video-class {
            position: absolute;
            width: 50%;
            height: 50%;
            object-fit: contain;
            bottom: 20%;
            right: 29%;
            overflow: hidden;
          }
        }
      }
    }

    .p2 {
      font-size: 1.5rem;
      padding-bottom: 1rem;
      padding-top: 10rem;
      text-align: center;
    }

    .founder-info-container {
      margin-top: 1rem;
      margin-bottom: 1rem;
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      width: 100%;
      height: auto;

      .founder {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      h1 {
        font-size: 1.5rem;
        text-align: center;
      }

      p {
        font-size: 1rem;
      }

      .founder-info {
        width: 90%;
        display: flex;
        margin-left: 4%;
        margin-bottom: 10%;
        text-align: center;
      }
    }
    .download-container {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      z-index: 5;
      margin-top: 10rem;

      img {
        margin-bottom: 10rem;
      }
    }

    .pricing-text {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-top: 5rem;
      gap: 2rem;
      z-index: 5;

      h1 {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 1.5rem;
        color: $background;
        text-align: center;
        margin-bottom: 1rem;
        z-index: 5;
      }
    }

    .footer-text {
      position: relative;
      font-size: 0.8rem;
      padding-bottom: 1rem;
      text-align: center;
    }

    .p3 {
      position: relative;
      font-size: 1.4rem;
      padding-bottom: 1rem;
      padding-top: 5rem;
      padding-bottom: 8rem;
      text-align: center;
    }

    .social-media-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      z-index: 5;

      img {
        margin-left: 2.7%;
        width: 13%;
        height: 10%;
        object-fit: contain;
      }
    }
  }

  /* Reset styles for other elements */

  .form-container {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    transform: none !important;
    height: auto !important;
    width: 100% !important;
    display: block !important;
    margin: 0 !important;
  }

  .header,
  .text-container,
  .image-container,
  .form-container,
  .save-card-container,
  .contribute-card-container,
  .rewards-card-container,
  .last-page,
  .p2,
  .p3,
  .pricing-text,
  .founder-info-container,
  .bottom-logo-container,
  .background-image-container,
  .download-container,
  .footer-text,
  .social-media-container {
    position: relative !important;
    top: auto !important;
    transform: none !important;
    height: auto !important;
    width: 100% !important;
    display: block !important;
    margin: 0 !important;
    overflow: hidden;
  }
}
